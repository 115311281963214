export default function Icone3() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="black" />
            <path
                d="M12.4452 26H6.99586V16.4571H5V10.262H10.1898C9.90714 9.92696 9.69719 9.54042 9.57249 9.12539C9.44778 8.71037 9.41087 8.27535 9.46395 7.84626C9.51703 7.41717 9.65902 7.00278 9.88146 6.6278C10.1039 6.25282 10.4022 5.92492 10.7587 5.66363C11.1151 5.40234 11.5224 5.21301 11.9562 5.10692C12.39 5.00084 12.8415 4.98017 13.2838 5.04614C13.7261 5.11212 14.1501 5.26339 14.5305 5.49095C14.911 5.7185 15.2401 6.01768 15.4983 6.37066C15.7558 6.01616 16.0848 5.71547 16.4654 5.48657C16.8461 5.25768 17.2707 5.10529 17.7137 5.03851C18.1568 4.97174 18.6093 4.99195 19.0441 5.09795C19.4789 5.20395 19.8871 5.39355 20.2442 5.6554C20.6014 5.91726 20.9002 6.246 21.1227 6.62195C21.3452 6.9979 21.487 7.41333 21.5394 7.84339C21.5918 8.27345 21.5539 8.70931 21.4278 9.12488C21.3017 9.54044 21.0902 9.92719 20.8057 10.262H26V16.4571H23.9918V25.9995L12.4452 26ZM22.7556 24.8068V16.4571H18.4688V24.8068H22.7556ZM17.2327 24.8068V11.4671H13.6813V24.8068H17.2327ZM8.23153 24.8068H12.4452V16.4571H8.23202L8.23153 24.8068ZM24.7643 15.2639V11.4552H18.4688V15.2639H24.7643ZM6.23567 15.2639H12.4452V11.4552H6.23567V15.2639ZM16.1181 8.23255V10.262H18.2177C18.633 10.262 19.039 10.1431 19.3842 9.92027C19.7295 9.69749 19.9986 9.38083 20.1576 9.01035C20.3165 8.63988 20.3581 8.23221 20.277 7.83891C20.196 7.44561 19.9961 7.08435 19.7024 6.80079C19.4088 6.51724 19.0347 6.32414 18.6274 6.24591C18.2201 6.16768 17.7979 6.20783 17.4142 6.36128C17.0306 6.51474 16.7027 6.77461 16.4719 7.10804C16.2412 7.44146 16.1181 7.83346 16.1181 8.23446V8.23255ZM10.6777 8.23255C10.6779 8.77038 10.8992 9.28613 11.2928 9.66661C11.6865 10.0471 12.2204 10.2612 12.7773 10.262H14.877V8.23255C14.877 7.69457 14.6557 7.17862 14.2617 6.79821C13.8678 6.4178 13.3335 6.20409 12.7763 6.20409C12.2192 6.20409 11.6849 6.4178 11.291 6.79821C10.897 7.17862 10.6757 7.69457 10.6757 8.23255H10.6777Z"
                fill="black"
            />
        </svg>
    );
}
